require("corejs-typeahead");

$(document).ready(function($) {
  window.Bloodhound = require("bloodhound-js");

  var products = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.whitespace,
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: '/api/products/autocomplete?q=%QUERY',
      wildcard: '%QUERY'
    }
  });

  $('#search-terms').typeahead({
    highlight: true
  }, {
    source: products,
    templates: {
      header: '<h3 class="search-title">Products</h3>',
      suggestion: function(data) {
        return '<a href="/products/' + data + '">' + data + '</a>';
      }
    },
    limit: 25
  }).on('keypress', function(e) {
    var q;
    if (e.which === 13) {
      q = $('#search-terms').val();
      Cookies.set('last_query', q, {
        expires: 7
      });
      window.location.href = '/products?q=' + encodeURI(q);
      return false;
    }
  });

  $('#search-mobile').typeahead({
    highlight: true
  }, {
    source: products,
    templates: {
      header: '<h3 class="search-title">Products</h3>',
      suggestion: function(data) {
        return '<a href="/products/' + data + '">' + data + '</a>';
      }
    },
    limit: 25
  }).on('keypress', function(e) {
    var q;
    if (e.which === 13) {
      q = $('#search-mobile').val();
      Cookies.set('last_query', q, {
        expires: 7
      });
      window.location.href = '/products?q=' + encodeURI(q);
      return false;
    }
  });

  $('.search-clear').click(function() {
    $('.search-field').typeahead('val', '');
    Cookies.remove('last_query');
    return false;
  });

  $('#search-mobile-submit').click(function() {
    var q;
    q = $('#search-mobile').val();
    Cookies.set('last_query', q, {
      expires: 7
    });
    window.location.href = '/products?q=' + encodeURI(q);
    return false;
  });
});
